import * as Sentry from '@sentry/node';

/* eslint-disable no-undef */
module.exports = {
  initSentry: () => {
    Sentry.init({
      dsn:
        typeof window === 'undefined'
          ? process.env.SENTRY_ID
          : window.SENTRY_ID,
    });
  },
  captureException: (...args) => {
    Sentry.captureException(args);
  },
};
/* eslint-enable no-undef */
